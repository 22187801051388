<template>
  <div class="cabinet-wrap">
    <el-form
      ref="form"
      size="small"
      class="search-pannel"
      label-position="left"
      :model="searchForm"
      @submit.native.prevent
    >
      <el-form-item label="原设备编码：">
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="searchForm.customerDeviceCodeArrStr"
          :autosize="autosize"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="设备编码：">
        <el-input type="textarea" placeholder="请输入内容" v-model="searchForm.deviceCodeArrStr" :autosize="autosize">
        </el-input>
      </el-form-item>
      <el-form-item label="柜机编码：">
        <el-input
          type="textarea"
          placeholder="请输入柜机编码"
          v-model="searchForm.lockerCodeArrStr"
          :autosize="autosize"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="内部型号：">
        <el-input placeholder="请输入内部型号" v-model="searchForm.productCodeLike"></el-input>
      </el-form-item>
      <el-form-item label="模版ID：">
        <el-input placeholder="请输入模版ID" v-model="searchForm.deviceTemplateCodeLike"></el-input>
      </el-form-item>
      <el-form-item label="设备所属：">
        <Select clearable placeholder="请选择设备" v-model="searchForm.deviceOwner" :list="deviceOwnerList"></Select>
      </el-form-item>
      <el-form-item label="主/副柜：">
        <Select
          clearable
          placeholder="请选择主/副柜"
          v-model="searchForm.deviceMainSub"
          :list="deviceMainSubList"
        ></Select>
      </el-form-item>
      <el-form-item label="企业名称：">
        <el-input v-model="searchForm.customerName" placeholder="请输入企业名称"></el-input>
      </el-form-item>
      <el-form-item label="供应商：">
        <Select
          clearable
          placeholder="请选择供应商"
          v-model="searchForm.manufactureCode"
          :list="manufactureCodeList"
        ></Select>
      </el-form-item>
      <el-form-item label="设备状态：">
        <Select clearable placeholder="请选择设备状态" v-model="searchForm.status" :list="statusList"></Select>
      </el-form-item>
      <el-form-item label="激活时间：">
        <el-date-picker
          v-model="activationTimeList"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="monitorTime($event, 'activation')"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="最后一次激活时间：" label-width="150px">
        <el-date-picker
          v-model="activationLastTimeList"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="monitorTime($event, 'last')"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="导入时间：">
        <el-date-picker
          v-model="importTimeList"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="monitorTime($event, 'import')"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="烧录编码：">
        <Select clearable placeholder="请选择烧录编码" v-model="searchForm.deviceBurn" :list="burnWayList"></Select>
      </el-form-item>
      <el-form-item>
        <el-button type="success" size="small" native-type="submit" @click="onSearch">搜索</el-button>
        <el-button size="small" @click="onReset('form')">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row>
      <el-col :span="22">
        <el-button type="success" size="small" @click="newlyAdded('add')">新增</el-button>
        <el-button type="success" size="small" @click="handleExport">导 出</el-button>
        <el-button size="small" @click="handleEdit">编辑</el-button>
        <el-button size="small" @click="handleDelete">删除</el-button>
      </el-col>
    </el-row>

    <Table
      :loading="tableLoading"
      :header-cell-style="{ background: '#F7F8FA ' }"
      :columns="columns"
      :data="tableData"
      :total="pageParams.total"
      :page="pageParams.pageNum"
      :limit="pageParams.pageSize"
      :hidden="!tableData.length"
      @selection-change="handleSelectionChange"
      @pagination="updatePage"
    >
      <el-table-column slot="selection" type="selection" width="50" fixed="left"> </el-table-column>
      <template #deviceMainSub="{ originScope }">
        {{ originScope.row.deviceMainSub === 1 ? '主柜' : '副柜' }}
      </template>
      <template #deviceBurn="{ originScope }">
        {{ originScope.row.deviceBurn === 0 ? '否' : '是' }}
      </template>
      <template #deviceOwner="{ originScope }">
        {{ originScope.row.deviceOwner === 1 ? '丰巢机' : '客户机' }}
      </template>
      <template #status="{ originScope }">
        {{ originScope.row.status === 0 ? '未激活' : '已激活' }}
      </template>
      <!-- <el-table-column slot="operate" label="最后一次时间" width="150" fixed="right">
        <template #default="scope">
        {{scope.row.currentActiveSysTm}}
        </template>
      </el-table-column> -->
    </Table>

    <!-- 新增 & 编辑 -->
    <Dialog width="576px" :title="dlogstatusTitle" :visible="visible" @close="closeDialog">
      <div class="equipment-add-wrap">
        <el-form
          v-if="!visibleBatch"
          class="custom-form search-pannel"
          label-width="120px"
          label-position="left"
          size="small"
          ref="ruleForm"
          :rules="rules"
          :model="form"
        >
          <el-form-item label="设备数量：" prop="deviceCount" v-if="!isEdit">
            <el-input v-model="form.deviceCount"></el-input>
          </el-form-item>
          <el-form-item label="设备编号：" v-else>
            <el-input disabled v-model="form.deviceCode"></el-input>
          </el-form-item>
          <el-form-item label="设备所属：" prop="deviceOwner">
            <Select
              :disabled="isEdit"
              v-model="form.deviceOwner"
              :c-style="cStyle"
              :list="deviceOwnerList"
              @change="handlePublicSelect($event, 'deviceOwner')"
            ></Select>
          </el-form-item>
          <el-form-item label="主/副柜：" prop="deviceMainSub">
            <Select
              :disabled="isEdit"
              v-model="form.deviceMainSub"
              :c-style="cStyle"
              :list="deviceMainSubList"
              @change="handlePublicSelect($event, 'main')"
            ></Select>
          </el-form-item>
          <el-form-item label="原设备编码：" v-if="isEdit">
            <el-input v-model="form.customerDeviceCode"></el-input>
          </el-form-item>
          <el-form-item label="可视化模版：" prop="deviceTemplateCode">
            <el-select
              v-model="form.deviceTemplateCode"
              popper-class="custom-autocomplete"
              filterable
              remote
              clearable
              :disabled="!form.deviceMainSub"
              style="width: 280px"
              reserve-keyword
              placeholder="请输入可视化模版"
              :remote-method="querySearchAsync"
              :loading="loading"
              @change="templateChange"
            >
              <el-option
                v-for="item in templateListOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <!-- <el-autocomplete
              popper-class="custom-autocomplete"
              placeholder="请输入可视化模版"
              v-model="form.deviceTemplateName"
              :trigger-on-focus="false"
              :disabled="isdevTemp"
              :fetch-suggestions="querySearchAsync"
              @select="handleSelectCodeLike($event, 'temp')"
            >
              <template slot-scope="{ item }">
                <div class="name">{{ item.deviceTemplateName + '(' + item.deviceTemplateCode + ')' }}</div>
              </template>
            </el-autocomplete> -->
          </el-form-item>
          <el-form-item label="内部型号：" :prop="form.deviceOwner === 1 ? 'productCode' : ''">
            <Select
              clearable
              :disabled="!form.deviceTemplateCode"
              v-model="form.productCode"
              :c-style="cStyle"
              :list="productCodeList"
            ></Select>
          </el-form-item>
          <el-form-item label="供应商：">
            <Select
              v-model="form.manufactureCode"
              :c-style="cStyle"
              :list="manufactureCodeList"
              @change="handlePublicSelect($event, 'manu')"
            ></Select>
          </el-form-item>
          <el-form-item label="企业：">
            <el-autocomplete
              popper-class="custom-autocomplete"
              placeholder="请输入企业"
              v-model="form.customerName"
              :trigger-on-focus="false"
              :fetch-suggestions="querySearchAsyncCode"
              @select="handleSelectCodeLike($event, 'code')"
            >
              <template slot-scope="{ item }">
                <div class="name">{{ item.customerName }}</div>
              </template>
            </el-autocomplete>
          </el-form-item>
        </el-form>
        <el-row style="margin-top: 42px" type="flex" justify="end">
          <el-button @click="visible = false" size="small">返 回</el-button>
          <el-button type="success" @click="submitNewEquipment" size="small">{{
            isEdit ? '确 定' : '导 入'
          }}</el-button>
        </el-row>
      </div>
    </Dialog>

    <!-- 批量 -->
    <Dialog width="576px" :title="'批量编辑'" :visible="visibleBatch" @close="closeDialog">
      <div class="equipment-add-wrap">
        <el-form
          class="custom-form search-pannel"
          label-width="120px"
          label-position="left"
          ref="ruleForm"
          :rules="rules"
          :model="form"
        >
          <el-form-item v-show="isIdentical" label="可视化模版：" :prop="isIdentical ? 'deviceTemplateCode' : ''">
            <el-select
              v-model="form.deviceTemplateCode"
              popper-class="custom-autocomplete"
              filterable
              remote
              clearable
              reserve-keyword
              style="width: 280px"
              placeholder="请输入可视化模版"
              @change="templateChange"
              :remote-method="querySearchAsync"
              :loading="loading"
            >
              <el-option
                v-for="item in templateListOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <!-- <el-autocomplete
              popper-class="custom-autocomplete"
              placeholder="请输入可视化模版"
              v-model="form.deviceTemplateName"
              :trigger-on-focus="false"
              :fetch-suggestions="querySearchAsync"
              :disabled="isdevTemp"
              @select="handleSelectCodeLike($event, 'temp')"
            >
              <template slot-scope="{ item }">
                <div class="name">{{ item.deviceTemplateName + '(' + item.deviceTemplateCode + ')' }}</div>
              </template>
            </el-autocomplete> -->
          </el-form-item>
          <el-form-item
            v-show="isIdentical"
            label="内部型号："
            :prop="form.deviceOwner === 1 && isIdentical ? 'productCode' : ''"
          >
            <Select
              clearable
              v-model="form.productCode"
              :c-style="cStyle"
              :disabled="!form.deviceTemplateCode"
              :list="productCodeList"
            ></Select>
          </el-form-item>

          <el-form-item label="供应商：" v-show="isIdentical">
            <Select
              v-model="form.manufactureCode"
              :c-style="cStyle"
              :list="manufactureCodeList"
              @change="handlePublicSelect($event, 'manu')"
            ></Select>
          </el-form-item>
          <el-form-item label="企业：">
            <el-autocomplete
              popper-class="custom-autocomplete"
              placeholder="请输入企业"
              v-model="form.customerName"
              :trigger-on-focus="false"
              :fetch-suggestions="querySearchAsyncCode"
              @select="handleSelectCodeLike($event, 'code')"
            >
              <template slot-scope="{ item }">
                <div class="name">{{ item.customerName }}</div>
              </template>
            </el-autocomplete>
          </el-form-item>
        </el-form>
        <el-row style="margin-top: 42px" type="flex" justify="end">
          <el-button @click="visibleBatch = false" size="small">返 回</el-button>
          <el-button type="success" @click="submitBatchEdit" size="small">保 存</el-button>
        </el-row>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Table from '@/components/TablePage'
import Dialog from '@/components/Dialog'
import Select from '@/components/Select'
import {
  getEquipmentList,
  createEquipmentList,
  queryHardware,
  getVendorList,
  searchTemp,
  batchEdit,
  singleEdit,
  dropTelist,
  saveOrUpdate,
  getEnterpriseList
} from '@/api/modules/device'
export default {
  name: 'Cabinet',
  data() {
    return {
      columns: [
        { label: '设备编码', prop: 'deviceCode' },
        { label: '原设备编码', prop: 'customerDeviceCode' },
        { label: '柜机编码', prop: 'lockerCode' },
        { label: '烧录编码', prop: 'deviceBurn' },
        { label: '设备所属', prop: 'deviceOwner' },
        { label: '企业名称', prop: 'customerName' },
        { label: '设备状态', prop: 'status' },
        { label: '位置', prop: 'devicePosition' },
        { label: '内部型号', prop: 'productName' },
        { label: '主/副柜', prop: 'deviceMainSub' },
        { label: '模版ID', prop: 'deviceTemplateCode' },
        { label: '供应商', prop: 'manufactureName' },
        { label: '操作人', prop: 'createUser' },
        { label: '创建时间', prop: 'createSysTm', width: 160 },
        { label: '激活时间', prop: 'activeSysTm', width: 160 },
        { label: '最后一次激活时间', prop: 'currentActiveSysTm', width: 160 }
      ],
      tableData: [],
      pageParams: {
        pageNum: 1,
        pageSize: 10,
        total: 1
      },
      autosize: { minRows: 1, maxRows: 2 },
      searchForm: {
        customerDeviceCodeArrStr: '',
        deviceCodeArrStr: '',
        lockerCodeArrStr: '',
        productCodeLike: '',
        deviceTemplateCodeLike: '',
        deviceOwner: '',
        deviceMainSub: '',
        activeSysTmStart: '',
        activeSysTmEnd: '',
        currentActiveSysTmStart: '',
        currentActiveSysTmEnd: '',
        createSysTmStart: '',
        createSysTmEnd: '',
        status: '',
        deviceBurn: '',
        customerName: ''
      },
      form: {
        deviceCount: '',
        deviceCode: '',
        customerCode: '',
        customerName: '',
        deviceMainSub: '',
        customerDeviceCode: '',
        deviceOwner: 1,
        deviceTemplateCode: '',
        deviceTemplateName: '',
        manufactureCode: '',
        manufactureName: '',
        productCode: ''
      },
      activationTimeList: [],
      activationLastTimeList: [],
      importTimeList: [],
      deviceOwnerList: [
        { value: 1, label: '丰巢机' },
        { value: 2, label: '客户机器' }
      ],
      deviceMainSubList: [
        { value: 1, label: '主柜' },
        { value: 2, label: '副柜' }
      ],
      manufactureCodeList: [],
      statusList: [
        { value: 0, label: '未激活' },
        { value: 1, label: '已激活' }
      ],
      burnWayList: [
        { value: 0, label: '否' },
        { value: 1, label: '是' }
      ],
      productCodeList: [],
      dlogstatus: '',
      visible: false,
      visibleBatch: false,
      isdevCode: false,
      isdevTemp: false,
      cStyle: { width: '280px' },
      selectedRows: [],
      isIdentical: false,
      tableLoading: false,
      deviceCodeList: [],
      templateListOptions: []
    }
  },
  components: {
    Table,
    Select,
    Dialog
  },
  computed: {
    rules() {
      const confirmNumberRule = (rule, value, callback) => {
        const rules = /^\d{1,}$/
        const minvalue = 1
        const maxvalue = 1000
        if (!rules.test(value)) {
          callback(new Error('请输入数字'))
        }
        if (value < minvalue || value > maxvalue) {
          callback(new Error('请输入1-1000的数字'))
        }
        callback()
      }
      return {
        deviceCount: [
          { validator: confirmNumberRule, trigger: 'blur' },
          { required: true, message: '请输入设备数量', trigger: 'blur' }
        ],
        deviceOwner: [{ required: true, message: '请选择设备', trigger: 'change' }],
        deviceTemplateCode: [{ required: true, message: '请输入可视化模版', trigger: 'change' }],
        deviceMainSub: [{ required: true, message: '请选择主/副柜', trigger: 'change' }]
      }
    },
    isEdit() {
      return this.dlogstatus === 'edit'
    },
    dlogstatusTitle() {
      if (this.dlogstatus === 'edit') {
        return '编辑'
      } else {
        return '新增'
      }
    }
  },
  created() {
    this.onSearch(true)
    this.getVendorList()
  },
  mounted() {
    // this.$nextTick(() => {
    //   if (!this.form.deviceMainSub) {
    //     this.isdevTemp = true
    //   }
    // })
  },
  methods: {
    // 获取参数
    getParams(flag) {
      const getParam = { ...this.searchForm }
      getParam.pageNum = flag ? 1 : this.pageParams.pageNum
      getParam.pageSize = this.pageParams.pageSize
      return getParam
    },
    onSearch(flag) {
      const data = {
        ...this.getParams(flag)
      }
      this.tableLoading = true
      getEquipmentList(data)
        .then((res) => {
          const { records = [], pageNum, pageSize, total = 0 } = res.data
          this.tableData = records
          this.pageParams = { pageNum, pageSize, total }
          console.log('getEquipmentList-res: ', res)
        })
        .finally(() => {
          this.tableLoading = false
        })
    },
    onReset(formName) {
      this.$refs[formName].resetFields()
      this.activationTimeList = []
      this.activationLastTimeList = []
      this.importTimeList = []
      this.searchForm = {
        customerDeviceCodeArrStr: '',
        deviceCodeArrStr: '',
        lockerCodeArrStr: '',
        productCodeLike: '',
        deviceTemplateCodeLike: '',
        deviceOwner: '',
        deviceMainSub: '',
        activeSysTmStart: '',
        activeSysTmEnd: '',
        currentActiveSysTmStart: '',
        currentActiveSysTmEnd: '',
        createSysTmStart: '',
        createSysTmEnd: '',
        status: '',
        deviceBurn: ''
      }
    },
    templateChange() {
      this.form.productCode = ''
      this.queryHardware()
    },
    // 获取内部型号列表
    queryHardware() {
      if (!this.form.deviceTemplateCode) return
      queryHardware({ pageNum: 1, pageSize: 9999, deviceTemplateCode: this.form.deviceTemplateCode }).then((res) => {
        console.log('queryHardware-res: ', res)
        this.productCodeList = res.data.records || []
        this.productCodeList.forEach((item) => {
          item.value = item.productCode
          item.label = item.productName
        })
      })
    },
    // 获取供应商列表
    getVendorList() {
      getVendorList({ pageNum: 1, pageSize: 9999, entryVariable: 'supplier' }).then((res) => {
        console.log('res: ', res)
        const { records } = res.data || []
        this.manufactureCodeList = records || []
        this.manufactureCodeList.forEach((item) => {
          item.value = item.itemCode
          item.label = item.itemValue
        })
      })
    },
    // 新增
    newlyAdded(type) {
      this.visible = true
      this.isdevTemp = true
      this.isdevCode = true
      this.dlogstatus = type
      this.getVendorList()
      console.log('newlyAdded!')
    },
    // 处理表格选中
    handleSelectionChange(selection) {
      console.log('handleSelectionChange!', selection)
      this.selectedRows = selection
    },
    // 处理编辑，删除提示
    batchAction(confirmTips, cb) {
      const rows = this.selectedRows
      return this.$confirm(confirmTips)
        .then((...args) => {
          cb.apply(this, [rows, ...args])
        })
        .catch()
    },
    // 处理编辑
    handleEdit() {
      this.getVendorList()
      const emptyTips = '请选择内容进行编辑'
      const rows = this.selectedRows
      if (!rows.length) {
        this.$message.warning(emptyTips)
        return
      }
      const isActivationStatus = 1
      const can = rows.filter((item) => item.status === isActivationStatus)
      if (!can.length) {
        const ids = rows.filter((item) => item.status !== isActivationStatus)
        console.log('ids: ', ids)
        if (ids.length > 1) {
          // 批量
          this.batchEdit(ids)
        } else {
          // 单个
          this.singleEdit(ids)
          this.dlogstatus = 'edit'
        }
      } else {
        this.$message.warning('存在已激活的柜机，不能进行操作，请重新选择！')
      }
    },
    // 处理删除
    handleDelete() {
      const confirmTips = '确认对选中的内容进行删除吗？'
      const emptyTips = '请选择内容进行删除'
      const selectList = this.selectedRows
      if (!selectList.length) {
        this.$message.warning(emptyTips)
      } else {
        const isActivationStatus = 1
        const can = selectList.filter((item) => item.status === isActivationStatus)
        if (!can.length) {
          const action = (rows) => {
            // console.log('can: ', can)
            if (!can.length) {
              const ids = rows.map((item) => item.status !== isActivationStatus && item.deviceCode)
              this.dropTelist(ids)
            }
          }
          this.batchAction(confirmTips, action)
        } else {
          this.$message.warning('存在已激活的柜机，不能进行操作，请重新选择！')
        }
      }
    },
    // 处理导出
    handleExport() {
      const data = { ...this.getParams() }
      saveOrUpdate(data).then((res) => {
        console.log('res: ', res)
        if (res.type === 'application/json' || res.type === 'text/plain') {
          this.$message.warning('不支持所有数据导出，请设置查询条件，导出查询结果！')
          return
        }
        const url = window.URL.createObjectURL(new Blob([res], { type: res.type }))
        const a = document.createElement('a')
        document.body.appendChild(a)
        const fileName = '设备列表'
        a.href = url
        a.download = fileName
        a.click()
        window.URL.revokeObjectURL(url)
      })
    },
    // 处理编辑接口（批量）
    batchEdit(idsArr) {
      this.visibleBatch = true
      const rowarr = idsArr || []
      console.log('rowarr: ', rowarr)
      // 全部为丰巢设备
      const isBox = rowarr.every((item) => item.deviceOwner === 1)
      // 全部为客户设备
      const isCustomer = rowarr.every((item) => item.deviceOwner === 2)
      // 全部为主柜
      const isMain = rowarr.every((item) => item.deviceMainSub === 1)
      // 全部为副柜
      const isCabinet = rowarr.every((item) => item.deviceMainSub === 2)

      const isBoxMain = isBox && isMain
      const isBoxCabinet = isBox && isCabinet

      const isCustomerMain = isCustomer && isMain
      const isCustomerCabinet = isCustomer && isCabinet

      if (isBoxMain || isBoxCabinet || isCustomerMain || isCustomerCabinet) {
        this.isIdentical = true
        this.form.deviceMainSub = rowarr[0].deviceMainSub
        this.form.deviceOwner = rowarr[0].deviceOwner
        this.deviceCodeList = rowarr.map((item) => item.deviceCode)
        this.queryHardware()
      } else {
        this.deviceCodeList = rowarr.map((item) => item.deviceCode)
        this.form.customerName = rowarr[0].customerName
        this.isIdentical = false
      }
      // 为丰巢机 则可视化模版禁用
      isBox ? (this.isdevTemp = true) : (this.isdevTemp = false)

      console.log('cabinetBox: ', isBoxMain, isBoxCabinet, isCustomerMain, isCustomerCabinet)
    },
    // 处理编辑接口（单个）
    singleEdit(ids) {
      console.log('singleEdit============================================================')
      const row = ids[0]
      console.log('row: ', row)
      this.visible = true
      this.form.deviceMainSub = row.deviceMainSub
      this.form = {
        deviceCode: row.deviceCode,
        deviceOwner: row.deviceOwner,
        deviceMainSub: row.deviceMainSub,
        productCode: row.productCode,
        customerDeviceCode: row.customerDeviceCode,
        deviceTemplateName: row.deviceTemplateName,
        deviceTemplateCode: row.deviceTemplateCode,
        manufactureCode: row.manufactureCode,
        manufactureName: row.manufactureName,
        customerCode: row.customerCode,
        customerName: row.customerName
      }
      this.querySearchAsync()
      if (this.form.productCode) {
        this.isdevTemp = true
      } else {
        this.isdevTemp = false
      }
    },
    // 处理删除接口
    dropTelist(ids) {
      dropTelist({ deviceCodeList: ids }).then((res) => {
        if (res.code === '000000000') {
          this.$message.success(res.msg)
          this.onSearch(false)
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 提交新增&编辑
    submitNewEquipment() {
      console.log('submitNewEquipment!')
      const data = this.form
      this.$refs.ruleForm.validate(async(valid) => {
        if (!valid) return
        if (this.dlogstatus === 'add') {
          this.handleaddExit(createEquipmentList, data)
        } else {
          this.handleaddExit(singleEdit, data)
        }
      })
    },
    // 批量
    submitBatchEdit() {
      const data = {
        customerCode: this.form.customerCode,
        customerName: this.form.customerName,
        deviceCodeList: this.deviceCodeList || [],
        deviceTemplateCode: this.form.deviceTemplateCode,
        deviceTemplateName: this.form.deviceTemplateName,
        productCode: this.form.productCode,
        manufactureCode: this.form.manufactureCode,
        manufactureName: this.form.manufactureName
      }
      this.$refs.ruleForm.validate(async(valid) => {
        if (!valid) return
        this.handleaddExit(batchEdit, data)
      })
    },
    handleaddExit(fn, data) {
      fn(data).then((res) => {
        if (res.code === '000000000') {
          this.$message.success(res.msg)
          this.visibleBatch = false
          this.visible = false
          this.dlogstatus === 'add' ? this.onSearch(true) : this.onSearch(false)
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    // 更新分页
    updatePage(val) {
      if (val) {
        this.pageParams.pageNum = val.page
        this.pageParams.pageSize = val.limit
        this.onSearch()
      }
    },
    // 监听时间变化
    monitorTime(val, type) {
      const timedata = []
      val &&
        val.forEach((item) => {
          timedata.push(new Date(item).getTime())
        })
      const [start, end] = timedata
      if (type === 'activation') {
        this.searchForm.activeSysTmStart = start
        this.searchForm.activeSysTmEnd = end
        this.onSearch()
      } else if (type === 'last') {
        this.searchForm.currentActiveSysTmStart = start
        this.searchForm.currentActiveSysTmEnd = end
        this.onSearch()
      } else {
        this.searchForm.createSysTmStart = start
        this.searchForm.createSysTmEnd = end
        this.onSearch()
      }
    },
    closeDialog() {
      console.log('closeDialog: ')
      this.visible = false
      this.isdevCode = false
      this.isdevTemp = false
      this.visibleBatch = false
      this.isIdentical = false
      this.dlogstatus = ''
      this.$refs.ruleForm.resetFields()
      this.form = {
        deviceCount: '',
        customerCode: '',
        customerName: '',
        deviceMainSub: '',
        deviceOwner: 1,
        deviceTemplateCode: '',
        deviceTemplateName: '',
        manufactureCode: '',
        manufactureName: '',
        productCode: ''
      }
      this.deviceCodeList = []
    },
    // 模糊查询 (可视化模版)
    querySearchAsync(queryString, cb, type) {
      const data = {
        pageNum: 1,
        pageSize: 9999,
        deviceTemplateLike: queryString,
        deviceMainSub: this.form.deviceMainSub
      }
      searchTemp(data).then((res) => {
        // console.log(cb, 'searchTemp-res: ', res)
        // cb(res.data.records)
        // this.form.deviceTemplateCode = val.deviceTemplateCode
        // this.form.deviceTemplateName = val.deviceTemplateName + '(' + val.deviceTemplateCode + ')'
        this.templateListOptions = res.data.records.map(item => ({
          label: item.deviceTemplateName + '(' + item.deviceTemplateCode + ')',
          value: item.deviceTemplateCode,
          ...item
        }))
      })
    },
    // 模糊查询 (企业)
    querySearchAsyncCode(queryString, cb) {
      const data = {
        pageNum: 1,
        pageSize: 9999,
        customerName: queryString
      }
      if (queryString) {
        getEnterpriseList(data).then((res) => {
          console.log('getEnterpriseList-res: ', res)
          cb(res.data.records)
        })
      }
    },
    // 处理模糊搜索赋值
    handleSelectCodeLike(val, type) {
      console.log(val)
      if (type === 'temp') {
        this.form.deviceTemplateCode = val.deviceTemplateCode
        this.form.deviceTemplateName = val.deviceTemplateName + '(' + val.deviceTemplateCode + ')'
      } else {
        this.form.customerCode = val.customerCode
        this.form.customerName = val.customerName
      }
    },
    // 下拉设备所属
    handlePublicSelect(value, type) {
      console.log('handlePublicSelect: ', value, type)
      if (type === 'deviceOwner') {
        this.form.productCode = ''
        this.form.deviceTemplateName = ''
        this.form.deviceMainSub = ''
        this.isdevCode = true
        if (value === 2) {
          this.clearValidate('ruleForm', 'productCode')
        }
      }
      if (type === 'main') {
        if (value) {
          this.isdevCode = false
          this.isdevTemp = false
        }
        this.form.productCode = ''
        this.form.deviceTemplateCode = ''
        this.querySearchAsync('')
        // this.queryHardware()
      }

      if (type === 'ctCode') {
        value ? (this.isdevTemp = true) : (this.isdevTemp = false)
        const newpro = this.productCodeList.filter((item) => item.value === value)
        const {
          deviceTemplateCode: code,
          deviceTemplate: { deviceTemplateName: name }
        } = newpro[0]
        this.form.deviceTemplateCode = code
        this.form.deviceTemplateName = name + '(' + code + ')'
      }
      // 供应商
      if (type === 'manu') {
        const newarr = this.manufactureCodeList.filter((item) => item.itemCode === this.form.manufactureCode)
        this.form.manufactureName = newarr[0].itemValue
      }
    },
    clearValidate(formName, type) {
      this.$refs[formName].clearValidate([type])
    }
  }
}
</script>

<style lang="scss" scoped>
.cabinet-wrap {
  padding: 24px;
}
.equipment-add-wrap {
  padding: 0 26px;
  .el-input,
  .el-autocomplete {
    width: 280px;
  }
}

.custom-autocomplete {
  li {
    line-height: normal;
    padding: 7px;
    .name {
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 12px;
      color: #b4b4b4;
    }
    .highlighted .name {
      color: #ddd;
    }
  }
}
.custom-form{
  gap: 20px !important;
}
</style>
